@import 'styles/mixins';
.block {
  background-image: url('/marathon21/prizes_bg_m.svg'),
    url('/marathon21/prizes_bg1.png'), url('/marathon21/prizes_bg2.png'),
    url('/marathon21/prizes_bg3.png');
  background-size: contain, auto 155px, 127px auto, 127px auto;
  background-position: center, center 95%, 85% 60%, 15% 60%;
  background-repeat: no-repeat;
  margin-top: 42px;
  margin-bottom: 50px;
  width: inherit;
  padding: 30px 20px;

  @include mediaMinM {
    background-image: url('/marathon21/prizes_bg.svg'),
      url('/marathon21/prizes_bg1.png'), url('/marathon21/prizes_bg2.png'),
      url('/marathon21/prizes_bg3.png');
    background-size: contain, 400px 200px, 127px auto, 127px auto;
    background-position: center, 60% 90%, 95% 40%, 95% 70%;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  flex-direction: column-reverse;
  @include mediaMinS {
    flex-direction: row;
  }

  .title {
    margin: 0 0 40px;
    font-weight: 300;
    font-size: 26px;
    line-height: 32px;
    color: #231f20;
    max-width: 230px;

    @include mediaMinS {
      font-size: 48px;
      max-width: 100%;
    }
  }

  .link {
    font-size: 14px;
    line-height: 22px;
    color: #231f20;
    transition: color 0.1s ease-in-out;

    &:hover {
      color: #5a9ce7;
    }
  }
}

.text {
  font-weight: 300;
  color: #231f20;
  font-size: 15px;
  line-height: 20px;

  @include mediaMinS {
    font-size: 18px;
    line-height: 24px;
    max-width: 760px;
  }

  ul {
    line-height: 44px;
    padding-left: 15px;
    li::marker {
      color: #fb8d73;
    }
  }
}

.subtext {
  .subheader {
    font-weight: bold;
    font-size: 17px;
    line-height: 26px;
    color: #eb6a4f;
    padding-bottom: 5px;
  }

  padding-bottom: 170px;
  padding-top: 100px;

  @include mediaMinM {
    padding-bottom: 0;
    padding-top: 0;
  }
}
