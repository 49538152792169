@import 'styles/mixins';

.block {
  min-height: 600px;
  margin-bottom: 30px;
  margin-left: -20px;
  margin-right: -20px;
  padding: 40px 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  background: url('/marathon21/accreditation-bg.png') center/cover no-repeat;

  @include mediaMinS {
    padding: 70px 20px 0 20px;
  }

  @include mediaMinM {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 60px;
    background-size: 100%;
    padding-left: 80px;
    padding-right: 80px;
  }
}

.title {
  margin: 0;
  font-size: 28px;
  font-weight: 300;
  line-height: 30px;

  color: #231f20;

  @include mediaMinS {
    font-size: 46px;
    line-height: 48px;
  }
}

.text {
  margin: 20px auto;
  max-width: 750px;

  font-size: 18px;
  font-weight: 300;
  line-height: 26px;
  color: #231f20;

  a {
    color: #5574d8;

    &:hover {
      color: #2e4aa4;
    }
  }
}
