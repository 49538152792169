@import 'styles/mixins';

.speakers {
  &.withBtn {
    padding-bottom: 60px;
  }

  .link {
    position: absolute;
    display: flex;
    align-items: center;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);

    > a {
      width: 150px;
      height: 55px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      border: 1px solid #579aea;
      border-radius: 12px;
      text-decoration: none;
      color: #000;
      box-sizing: border-box;

      @media screen and (max-width: $mBreak) {
        width: 139px;
        height: 40px;
      }

      &:hover {
        border-color: #2344b0;
      }
    }
  }
}

.slider {
  position: initial;
}
