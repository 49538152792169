@import 'styles/mixins';

.block {
  margin: 20px 0;

  background: linear-gradient(0deg, #f4f4f4, #f4f4f4), #effafe;
  border-radius: 40px;
}

.datesList {
  padding: 0;
  margin: 0 0 20px;
  display: flex;
  align-items: center;
  overflow: auto;

  list-style: none;
}

.dateItem {
  position: relative;
  margin-right: 30px;
  cursor: pointer;
  transition: color 0.3s ease;
  white-space: nowrap;

  font-size: 18px;
  font-weight: 300;
  line-height: 42px;
  color: #666c77;

  @include mediaMinM {
    font-size: 28px;
  }

  &:last-child {
    margin-right: 0;
  }

  &:after {
    position: absolute;
    right: -15px;
    content: '/';

    @include mediaMinM {
      right: -20px;
    }

    font-size: 18px;
    line-height: 42px;
    color: #666c77;

    @include mediaMinM {
      font-size: 28px;
    }
  }

  &:last-child::after {
    display: none;
  }
}

.dateItemActive {
  color: #5574d8;
}

.grid {
  column-count: unset;
  column-gap: 10px;

  @include mediaMinM {
    column-count: 2;
  }

  @include mediaMinL {
    column-count: 3;
  }
}
