@import 'styles/mixins';

.speaker {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 0 10px;
  .photo {
    width: 154px;
    height: 154px;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
  }
  .name {
    padding: 15px 0 9px;
  }
  img {
    object-fit: cover;
  }

  &.large {
    .photo {
      @include mediaMinXl {
        width: 230px;
        height: 230px;
      }
    }
  }
}
