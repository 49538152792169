.player {
  width: 100%;
  height: 400px;

  > iframe {
    width: 100%;
    height: 100%;

    border: none;
  }
}
