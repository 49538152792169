@import 'styles/mixins';

.navbar {
  display: flex;
  padding: 15px;
  align-items: center;
  position: relative;

  @include mediaMinM {
    padding: 5px 37px;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 100;
  }

  .logo {
    min-width: 68px;
    box-sizing: border-box;

    &.logoOnlyMobile {
      @include mediaMinM {
        display: none;
      }
    }

    @include mediaMinM {
      margin-right: 70px;
      min-width: 93px;
    }
  }

  .desktopLogo {
    display: none;

    @include mediaMinM {
      display: block;
    }
  }

  .nav {
    width: 100%;
    display: none;

    &.show {
      position: fixed;
      z-index: 100;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.9);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #fff;

      @include mediaMinM {
        position: static;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        background-color: transparent;
        color: #000;
      }
    }

    @include mediaMinM {
      margin: 0 auto 0 40px;
      display: flex;
    }

    .close {
      position: absolute;
      right: 10px;
      top: 10px;
      color: #fff;
      padding: 6px 10px;
      border: none;
      background: none;

      @include mediaMinM {
        display: none;
      }
    }

    a {
      display: block;
      padding: 4px 10px;
      font-size: 28px;
      line-height: 70px;
      font-weight: 350;
      transition: color 0.1s ease-in-out;

      &:hover {
        color: #5a9ce7;
      }

      @include mediaMinM {
        margin-left: -10px;
        font-size: 14px;
        line-height: 17px;

        &:not(:last-child) {
          margin-right: 20px;
        }
      }
    }
  }

  .hamburger {
    position: absolute;
    right: 0;
    top: 15px;
    width: 44px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;

    > button {
      border: none;
      background: none;
    }

    @include mediaMinM {
      display: none;
    }
  }

  .customContent {
    margin-left: auto;
    display: flex;
    align-items: center;
  }
}
