@import 'styles/mixins';

.block {
  padding: 20px 15px;
  margin-bottom: 30px;
  display: grid;
  grid-template-areas:
    'title title'
    'text text'
    'button icon';
  grid-gap: 20px;

  border: 1px solid #ebebeb;
  border-radius: 20px;

  @include mediaMinM {
    padding: 40px;
    grid-template-areas:
      'title title title title'
      'button icon text text';
    grid-gap: 30px;
  }
}

.title {
  grid-area: title;

  font-size: 18px;
  line-height: 22px;
  color: #231f20;
}

.text {
  grid-area: text;

  font-size: 15px;
  font-weight: 300;
  line-height: 20px;
  color: #181818;

  > span {
    color: #5574d8;
  }

  @include mediaMinM {
    align-self: center;
  }
}

.button {
  grid-area: button;
  width: 200px;
}

.icon {
  width: 66px;
  height: 66px;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-area: icon;

  border: 1px solid #ebebeb;
  border-radius: 50%;
}
