@import 'styles/mixins';

.block {
  position: relative;
  top: -18px;
  margin-right: 40px;

  @include mediaMinM {
    top: 0;
    margin: 0 10px;
  }
}

.link {
  font-size: 14px;
  text-transform: uppercase;
  transition: color 0.3s ease;

  &:hover {
    color: #426fff;
  }
}
