@import 'styles/mixins';

.wrapper {
  width: 100%;
  flex: 1;
  @include mediaMinM {
    padding-top: 100px;
  }
}

.container {
  margin: 0 auto;
  max-width: 1074px;

  padding: 0 15px;
  box-sizing: border-box;
}
