@import 'styles/mixins';

.container {
  margin-top: 40px;
  padding-bottom: 40px;

  border-bottom: 1px solid #e5e5e5;

  @include mediaMinM {
    display: flex;
    justify-content: space-between;
  }
}

.about {
  margin-bottom: 40px;

  @include mediaMinM {
    margin-bottom: 0;
    max-width: 390px;
  }

  h2 {
    font-size: 30px;
    line-height: 72px;
    display: inline-block;
    font-weight: 300;
    background: #f4f4f4;
    border-radius: 15px;
    margin: 0 0 20px;
    padding: 12px 30px;

    @include mediaMinM {
      font-size: 46px;
      line-height: 72px;
      margin: 0 0 36px;
    }
  }

  p {
    font-size: 16px;
    font-weight: 300;
    line-height: 26px;
    color: #231f20;

    @include mediaMinM {
      font-size: 17px;
      line-height: 26px;
    }
  }

  button {
    margin: 0;
    padding: 0;
    color: #426fff;
    font-size: 17px;
    line-height: 26px;
    cursor: pointer;
  }
}

.stats {
  display: flex;
  margin: 0;
  padding: 0;

  @include mediaMinM {
    margin-left: 40px;
  }

  li {
    width: 122px;
    height: 122px;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-weight: 300;
    font-size: 14px;
    line-height: 26px;

    @include mediaMinM {
      width: 15vw;
      max-width: 216px;
      min-width: 140px;
      height: 15vw;
      max-height: 216px;
      min-height: 140px;
      font-size: 16px;
    }

    @include mediaMinL {
      width: 216px;
      height: 216px;
    }

    & + li {
      margin-left: -14px;
    }

    strong {
      font-style: normal;
      font-weight: 300;
      font-size: 30px;
      line-height: 36px;
      color: #eb6a4f;

      @include mediaMinM {
        font-size: 52px;
        line-height: 69px;
      }
    }
  }
}
