@import 'styles/mixins';

.block {
  margin-bottom: 20px;

  @include mediaMinL {
    margin: 0 -100px 40px -100px;
  }
  @include mediaMinM {
    margin-bottom: 40px;
  }
  .descMain {
    padding: 0;
    position: absolute;
    top: 320px;

    @include mediaMinM {
      position: relative;
      padding-left: 35px;
      padding-top: 8px;
      top: 0;
    }

    .theme {
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 25px;

      @include mediaMinM {
        font-size: 34px;
        line-height: 40px;
      }
    }

    .text {
      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      line-height: 25px;
      color: #666c77;
      padding-top: 12px;

      @include mediaMinM {
        font-size: 22px;
        line-height: 32px;
      }
    }

    .name {
      font-style: normal;
      font-weight: 300;
      font-size: 22px;
      line-height: 32px;
      color: #231f20;
      padding-top: 5px;
      @include mediaMinM {
        font-size: 22px;
        line-height: 32px;
      }
    }
  }
}

.playerContainer {
  background-image: url('/marathon21/player_bg.svg');
  width: inherit;
  background-repeat: no-repeat;
  background-size: contain;
  height: calc(100vh - 110px);
  background-position: center;
  max-height: 550px;
  margin: 0 -20px;
  display: flex;
  flex-direction: column;
  @include mediaMinM {
    margin: 0;
    flex-direction: row;
    max-height: 625px;
  }
}

.listContainer {
  display: flex;
  width: 100vw;
  overflow-x: auto;
  padding-left: 15px;
  padding-top: 110px;

  @include mediaMinM {
    padding: 30px 20px 30px 30px;
    width: 30%;
    overflow-y: auto;
    overflow-x: hidden;
    display: block;
  }
}

.miniPlayer {
  display: flex;
  margin-bottom: 10px;
  height: auto;
  cursor: pointer;
  flex-direction: column;
  min-width: 150px;
  box-sizing: content-box;

  &:not(:last-child) {
    padding-right: 15px;
    @include mediaMinM {
      padding-right: 0;
    }
  }

  @include mediaMinM {
    flex-direction: row;
    height: 94px;
  }

  .desc {
    padding-left: 15px;
    padding-top: 8px;
    @include mediaMinM {
      padding-top: 0;
    }

    .theme {
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
    }

    .name {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 15px;
      color: #666c77;
      padding-top: 5px;
    }
  }

  .playerInner {
    width: 100%;
    height: 80px;
    position: relative;
    overflow: hidden;
    border-radius: 20px;

    @include mediaMinM {
      width: 153px;
      height: 100%;
      flex: 0 0 auto;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: transparent;
      cursor: pointer;
    }
  }

  .player {
    width: 100%;
    height: 100%;
    border-radius: 15px;
  }
}

.mainPlayer {
  padding: 0;
  box-sizing: content-box;
  width: 100%;
  height: 220px;

  @include mediaMinM {
    width: 70%;
    height: auto;
    padding: 30px 0 80px 30px;
    position: relative;
  }

  .playerInner {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    padding: 15px;
    @include mediaMinM {
      padding: 0;
    }
  }

  .player {
    width: 100%;
    height: 100%;
    border-radius: 20px;
  }
}
