.block {
  display: flex;
  align-items: center;
}

.item {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid #e1e4e8;
  display: flex;
  align-items: center;
  justify-content: center;

  &:last-child {
    margin-right: 0;
  }
}
