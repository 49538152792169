@import 'styles/mixins';

.block {
  position: relative;
  display: none;
  height: 350px;
  margin-bottom: 30px;

  @include mediaMinS {
    display: block;
  }

  @include mediaMinM {
    height: 450px;
  }

  @include mediaMinL {
    height: 634px;
    margin-bottom: 60px;
  }
}
