@import 'styles/mixins';

.block {
  margin-bottom: 30px;

  @include mediaMinM {
    margin-bottom: 60px;
  }
}

.title {
  font-size: 28px;
  font-weight: 300;
  line-height: 30px;

  color: #231f20;

  @include mediaMinS {
    font-size: 46px;
    line-height: 48px;
  }
}

.items {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.item {
  width: 100%;
  margin: 0 auto;
  max-width: 169px;
  height: 169px;
  padding-right: 8px;
  padding-bottom: 8px;

  &:last-child {
    padding-bottom: 0;
    padding-right: 0;
  }

  @include mediaMinS {
    width: 50%;
    max-width: 262px;
    height: 262px;
  }

  @include mediaMinM {
    width: 25%;
    margin: auto;
    padding-bottom: 0;
  }
}

.figure {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  border-radius: 60px;
  border: 1px solid transparent;
  transition: border-radius 0.3s ease;

  &:hover {
    border-radius: 50%;
  }

  .item:nth-child(1) & {
    background-color: #b498eb;
  }

  .item:nth-child(2) & {
    background-color: #f5f5f5;
  }

  .item:nth-child(3) & {
    background-color: #f1f4f9;
  }

  .item:nth-child(4) & {
    background-color: #fff;
    border-color: #ccd2dd;
  }
}

.value {
  font-size: 23px;
  line-height: 31px;
  color: #eb6a4f;

  .item:nth-child(1) & {
    color: #fff;
  }

  @include mediaMinM {
    font-size: 35px;
    line-height: 42px;
  }
}

.label {
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  padding: 0 10px;

  color: #000;

  @include mediaMinS {
    padding: 0;

    line-height: 26px;
    font-weight: normal;
  }
}
