@import 'styles/mixins';

.block {
  margin-top: 150px;
}

.header {
  padding-bottom: 60px;
  font-weight: 300;
  font-size: 26px;
  line-height: 32px;
  color: #231f20;

  @include mediaMinM {
    font-size: 46px;
    line-height: 48px;
  }
}

.content {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 60px;

  @include mediaMinM {
    // grid-template-columns: repeat(3, 1fr);
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 32px;
  }

  .item {
    position: relative;
    padding: 62px 0 15px 0;
    border: 1px solid #ccd2dd;
    box-sizing: border-box;
    border-radius: 30px;
    color: #181818;
    @include mediaMinM {
      padding: 92px 0 30px 0;
    }
    .img {
      width: 100px;
      height: 100px;

      @include mediaMinM {
        width: 120px;
        height: 120px;
        left: calc(50% - 60px);
      }
      position: absolute;
      top: -50px;
      left: calc(50% - 50px);
    }

    .name {
      text-align: center;
      width: 100%;
      font-weight: normal;
      letter-spacing: -0.03em;
      white-space: nowrap;
      padding-bottom: 42px;
      font-size: 22px;
      line-height: 35px;

      @include mediaMinM {
        font-size: 30px;
        line-height: 50px;
      }
    }

    .action {
      display: flex;
      justify-content: center;

      button {
        border-color: blue;
      }
    }

    &:hover {
      background-color: #ed694a;
      color: white;
    }
    &:hover button {
      border-color: transparent;
      background-color: white;
    }
  }
}
