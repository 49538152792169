@import 'styles/mixins';

.block {
  margin: 30px 0;

  @include mediaMinM {
    margin: 60px 0;
  }
}

.container {
  padding: 20px;

  border: 1px solid #ebebeb;
  border-radius: 30px;

  @include mediaMinL {
    padding: 40px 80px;
  }
}

.row {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;

  @include mediaMinM {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

.subtitle {
  width: 100%;
  margin: 0;
  padding-right: 20px;

  font-size: 26px;
  font-weight: 300;
  line-height: 32px;
  color: #231f20;

  @include mediaMinM {
    width: 60%;
    padding-right: 0;
  }
}

.logo {
  position: relative;
  width: auto;
  max-width: 335px;
  height: 130px;
  margin-left: -20px;

  @include mediaMinM {
    flex: 1;
    margin-right: 0;
  }
}

.note {
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
  color: #231f20;
}

.eventsList {
  padding: 0;
  margin: 40px 0 0;

  list-style: none;
}

.eventRow {
  position: relative;
  display: flex;
  flex-direction: column;

  @include mediaMinM {
    flex-direction: row;
    align-items: center;
  }
}

.eventImg {
  position: relative;
  width: 80px;
  min-width: 80px;
  height: 80px;
  margin-bottom: 20px;

  @include mediaMinM {
    margin-bottom: 0;
  }
}

.eventItem {
  padding: 10px 0;
  margin-bottom: 20px;

  cursor: pointer;
  border-bottom: 1px solid #ebebeb;

  &:last-child {
    margin-bottom: 0;
    border-bottom: none;
  }
}

.eventInfo {
  @include mediaMinM {
    margin-left: 30px;
  }
}

.eventInfoTitle {
  margin: 0;
}

.eventInfoText {
  margin: 10px 0;
  padding-right: 20px;
  height: auto;
  max-height: 1000px;

  font-weight: 300;
  overflow: hidden;
  transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
}

.eventInfoTextCollapsed {
  margin: 0;
  max-height: 0;
  transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
}

.eventInfoTitle,
.eventInfoText {
  font-size: 18px;
  line-height: 24px;
}

.eventIcon {
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  min-width: 30px;
  padding-bottom: 1px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 22px;
  border: 2px solid #414141;
  border-radius: 50%;

  @include mediaMinM {
    position: inherit;
  }
}
