.speakersItem {
  display: flex;
  margin-top: 8px;
}

.speakersItemAvatar {
  width: 36px;
  height: 36px;
  min-width: 36px;
  margin-right: 12px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;

  img {
    max-width: 100%;
    width: 100%;
    border: 0;
    object-fit: cover;
  }
}

.speakersItemContent {
  flex: 1;
  font-size: 13px;
  line-height: 26px;

  b {
    display: block;
  }

  p {
    margin: 0;
    line-height: 16px;
    color: #666c77;
    font-size: 12px;
  }
}
