$xsBreak: 320px;
$sBreak: 481px;
$mBreak: 769px;
$lBreak: 989px;
$xlBreak: 1200px;
$descBreak: 1280px;

@mixin mediaMinXs {
  @media (min-width: $xsBreak) {
    @content;
  }
}

@mixin mediaMinS {
  @media (min-width: $sBreak) {
    @content;
  }
}

@mixin mediaMinM {
  @media (min-width: $mBreak) {
    @content;
  }
}

@mixin mediaMinL {
  @media (min-width: $lBreak) {
    @content;
  }
}

@mixin mediaMinXl {
  @media (min-width: $xlBreak) {
    @content;
  }
}

@mixin mediaMinDesc {
  @media (min-width: $descBreak) {
    @content;
  }
}

@mixin mediaMaxS {
  @media (max-width: $sBreak) {
    @content;
  }
}

@mixin mediaMaxM {
  @media (max-width: $mBreak) {
    @content;
  }
}

@mixin mediaMaxL {
  @media (max-width: $lBreak) {
    @content;
  }
}

@mixin mediaMaxXl {
  @media (max-width: $xlBreak) {
    @content;
  }
}

@mixin mediaMaxDesc {
  @media (max-width: $descBreak) {
    @content;
  }
}

@mixin h1 {
  font-size: 40px;
  line-height: 47px;
  font-weight: 400;
  text-align: left;
  color: black;

  @include mediaMinM {
    font-size: 82px;
    line-height: 90px;
  }
}

@mixin h2 {
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 35px;
  text-align: left;
  color: black;

  @include mediaMinM {
    font-size: 62px;
    line-height: 72px;
  }
}

@mixin text {
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  text-align: left;

  @media screen and (max-width: $mBreak) {
    font-size: 14px;
    line-height: 30px;
  }
}

@mixin flex($align: center, $justify: center, $direction: '') {
  display: flex;
  align-items: $align;
  justify-content: $justify;
  flex-direction: unquote($direction);
}
