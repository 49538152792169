@import 'styles/mixins';

.block {
  padding-bottom: 50px;
}

.title {
  margin-bottom: 50px;
  font-size: 24px;
  font-weight: 300;
  line-height: 30px;
  text-align: center;
  color: #231f20;
}

.list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(40%, 1fr));
  grid-gap: 30px;

  @include mediaMinS {
    grid-template-columns: repeat(auto-fit, minmax(30%, 1fr));
  }

  @include mediaMinM {
    grid-template-columns: repeat(auto-fit, minmax(20%, 1fr));
  }
}

.img {
  width: 100%;
  max-width: 140px;
  height: 60px;
  margin: 0 auto;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  @include mediaMinM {
    height: 70px;
  }
}

.stopcoronovirus {
  max-width: 180px;
}
