@import 'styles/mixins';

.inner {
  min-height: 83px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f4f4f4;
}

.col {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  &:last-child {
    margin-bottom: 0;
  }

  @include mediaMinM {
    flex-direction: row;
    text-align: left;

    & > *:not(:last-child) {
      margin: auto 20px auto 0;
    }
  }
}

.copyright {
  font-size: 12px;
  color: #373737;
  text-align: left;

  @include mediaMinS {
    margin-right: 20px;
  }
}

.socials {
  display: none;

  @include mediaMinM {
    display: block;
  }
}

.socialsMobile {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  @include mediaMinM {
    display: none;
  }
}
