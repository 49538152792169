@import 'styles/mixins';

.block {
  margin-top: 85px;
}

.header {
  font-weight: 300;
  font-size: 26px;
  line-height: 32px;
  color: #231f20;

  @include mediaMinM {
    font-size: 46px;
    line-height: 48px;
  }
}

.content {
  .item {
    display: flex;
    margin-bottom: 45px;
    flex-direction: column;

    @include mediaMinM {
      flex-direction: row;
    }

    .img {
      position: relative;
      object-fit: contain;
      width: 100%;
      height: 300px;
      flex: 0 0 auto;

      @include mediaMinM {
        width: 339px;
        height: 265px;
      }
    }

    .desc {
      padding-left: 0;
      padding-top: 12px;

      @include mediaMinM {
        padding-left: 30px;
        padding-top: 0;
      }

      .title {
        font-style: normal;
        font-weight: 300;
        font-size: 20px;
        line-height: 22px;
        color: #231f20;
        padding-bottom: 10px;
        @include mediaMinM {
          font-size: 26px;
          line-height: 32px;
        }
      }
      .date {
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 20px;
        color: #414141;
        padding-bottom: 20px;

        @include mediaMinM {
          padding-bottom: 40px;
          font-size: 18px;
          line-height: 24px;
        }
      }
      .text {
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 20px;
        color: #231f20;
        @include mediaMinM {
          font-size: 18px;
          line-height: 24px;
        }
      }
    }
  }
}
