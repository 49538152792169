@import 'styles/mixins';

.block {
  margin: 40px -20px 40px -20px;
  padding: 20px;

  background: linear-gradient(0deg, #f4f4f4, #f4f4f4), #effafe;
  border-radius: 40px;

  @include mediaMinM {
    padding: 40px;
    margin: 40px 0;
  }
}

.title {
  margin: 0;
  font-size: 32px;
  font-weight: 300;
  line-height: 36px;

  color: #231f20;

  @include mediaMinS {
    line-height: 72px;
  }

  @include mediaMinM {
    font-size: 46px;
  }
}

.subtitle {
  margin: 0;

  font-size: 18px;
  font-weight: 300;
  color: #666c77;
}

.citiesListWrap {
  overflow: hidden;
  user-select: none;
}

.citiesList {
  padding: 0;
  margin: 20px 0;
  display: flex;
  align-items: center;
  overflow: auto;

  list-style: none;
}

.cityItem {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  cursor: pointer;
  padding: 0 20px;
  margin-right: 10px;
  height: 100px;
  transition: background-color 0.3s ease;

  background-color: #ffffff;
  border-radius: 15px;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    .cityItemTitle {
      color: #426fff;
    }
  }
}

.cityItemActive {
  background-color: #426fff;

  &:hover {
    .cityItemTitle {
      color: #ffffff;
    }
  }
}

.cityItemTopic {
  font-size: 18px;
  font-weight: 300;
  line-height: 20px;
  color: #231f20;
  transition: color 0.3s ease;

  .cityItemActive & {
    color: #ffffff;
  }
}

.cityItemLabel {
  font-size: 13px;
  font-weight: 300;
  color: #666c77;
  white-space: nowrap;

  .cityItemActive & {
    color: #ffffff;
  }
}

.empty {
  margin: 40px 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  text-align: center;
}

.emptyTitle {
  margin-top: 20px;

  font-size: 26px;
  line-height: 32px;
}
