@import 'styles/mixins';

.block {
  position: relative;
  top: -18px;
  margin-right: 10px;

  @include mediaMinM {
    top: 0;
    margin-right: 0;
  }
}
