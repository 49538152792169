.item {
  padding: 20px;
  margin-bottom: 10px;
  -webkit-column-break-inside: avoid;
  overflow: hidden;

  border: 1px solid #f0f6fd;
  box-shadow: 0px 10px 40px rgba(222, 230, 237, 0.4);
  border-radius: 20px;
  background: #ffffff;
}

.itemTitle {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;

  color: #666c77;
  font-size: 13px;
  line-height: 18px;
}

.content {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;

  & > p {
    font-size: 13px;
    line-height: 16px;
    margin-bottom: 10px;
    color: #666c77;
  }
}

.subTitle {
  margin-bottom: 0px;

  font-size: 15px;
  font-weight: normal;
  line-height: 18px;
  color: #131315;

  & + * {
    margin-top: 8px;
  }
}

.itemTitleText {
  padding: 3px 7px;

  background: #ebf2fa;
  border-radius: 4px;
  z-index: 1;
}
