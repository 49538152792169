@import 'styles/mixins';

.block {
  position: relative;
}

.head {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include mediaMinS {
    flex-direction: row;
  }
}

.title {
  font-size: 22px;
  font-weight: 300;
  line-height: 26px;

  @include mediaMinS {
    line-height: 35px;
  }
}

.link {
  font-weight: 300;

  @include mediaMinS {
    margin-left: auto;
  }
}

.videoSlider {
  margin: 20px 0 40px;

  :global {
    .slick-slide {
      padding-right: 30px;
    }

    .slick-prev {
      top: 45%;
      left: -70px;
    }

    .slick-next {
      top: 45%;
      right: -40px;
    }
  }
}

.videoItem {
  outline: none;
}

.videoPreview {
  position: relative;
  height: 210px;

  cursor: pointer;
  border: 1px solid #ccd2dd;
  border-radius: 40px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;

  @include mediaMinS {
    height: 300px;
  }

  @include mediaMinM {
    height: 400px;
  }

  @include mediaMinL {
    height: 300px;
  }
}

.videoPlayer {
  position: relative;
  height: 200px;

  border-radius: 40px;
  background-color: #000000;

  @include mediaMinS {
    height: 400px;
  }

  > iframe {
    width: 100%;
    height: 100%;

    border-radius: 40px;
    border: none;
  }
}

.videoTitle {
  margin-top: 20px;

  font-size: 16px;
  line-height: 20px;
  color: #414141;

  @include mediaMinS {
    line-height: 24px;
  }
}
